<template>
  <div v-if="isReady" class="Reports__View">
    <EntityListLayout
      v-if="tabs.length"
      :tabs="tabs"
      ref="entity"
      @onNewEntitySave="onNewEntitySave"
    >
      <template #title>דוחות</template>
      <template #button>
        <!-- <b-button v-if="isAdmin"  @click="showNewEntity">הוספת דוח חדש</b-button> -->
         <router-link class="link-button"  v-if="isAdmin" to="/addreport">
            הוספת דוח חדש
         </router-link>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import ReportService from "@/services/ReportService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import ReportEntity from "@/views/ReportEntity.vue";
import VehicleContractEntity from "@/views/VehicleContractEntity.vue";
import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import NewReportEntity from "@/components/Entities/NewReportEntity.vue";
import { saveAs } from "file-saver";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isUserProfile() {
      return this.$store.state.auth.profileId;
    },
    isSosnaPortal() {
      return this.$store.state.profile?.sosna;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isEndUser() {
      return !this.isLeasingCompany;
    },
    isReady() {
      return this.isLeasingCompany !== null;
    },
  },
  created() {
    if (this.isReady) {
      this.create();
    }
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("reports");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: ReportEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "פרטי דוח",
        recordUrl: "reports",
      };

      const excelExport = [
        {
          title: "יצוא טבלה",
          action: this.export,
        },
        {
          title: "יצוא מקובץ לפי עיריות",
          action: this.exportByMunic,
        },
        {
          title: "יצוא מקובץ לפי לקוח",
          action: this.exportByClient,
        },
      ];
      const fields = [
        { ...reportsFields.Address, checked: false },
        { ...reportsFields.Alerts, checked: true },
        { ...reportsFields.ApprovalDate, checked: false },
        { ...reportsFields.ClientComment, checked: false },
        { ...reportsFields.CreateDate, checked: false },
        { ...reportsFields.CurrentAmount, checked: true },
        { ...reportsFields.DateTaken, checked: true },
        { ...reportsFields.Driver, checked: false },
        { ...reportsFields.DriverAmount, checked: false },
        { ...reportsFields.IsPaidByDriver, checked: false },
        {
          ...reportsFields.DriverTransferCompleteDate,
          checked: false,
          isHidden: this.isLeasingCompany,
        },
        {
          ...reportsFields.DriverTransferError,
          checked: false,
          isHidden: this.isLeasingCompany,
        },
        {
          ...reportsFields.DriverTransferPdfUrl,
          checked: false,
          // isHidden: this.isLeasingCompany,
        },
        {
          ...reportsFields.DriverTransferSignedDate,
          checked: false,
          isHidden: this.isLeasingCompany,
        },
        {
          ...reportsFields.DriverTransferStatus,
          checked: false,
          // isHidden: this.isLeasingCompany,
        },
        {
          ...reportsFields.DriverTransferredDate,
          checked: false,
          isHidden: this.isLeasingCompany,
        },
        { ...reportsFields.HasScreenshot, checked: false },
        { ...reportsFields.IsPaid, checked: false },
        { ...reportsFields.LastPayDate, checked: true },
        { ...reportsFields.ManagerComment, checked: false },
        { ...reportsFields.MuniIdNumber, checked: false },
        { ...reportsFields.MuniReferenceNumber, checked: false },
        { ...reportsFields.Municipality, checked: true },
        { ...reportsFields.OriginalAmount, checked: true },
        { ...reportsFields.OwnerName, checked: !this.isLeasingCompany },
        { ...reportsFields.PartialPaidAmount, checked: false },
        { ...reportsFields.PaymentDate, checked: false },
        { ...reportsFields.PaymentReferenceNumber, checked: false },
        { ...reportsFields.PaymentUrl, checked: true },
        {
          ...reportsFields.PlateNumber,
          checked: true,
          onClick: this.onPlateNumberClick,
        },
        { ...reportsFields.ProfileName, checked: true },
        { ...reportsFields.Reason, checked: false },
        { ...reportsFields.RelevantLaw, checked: false },
        { ...reportsFields.ReportCause, checked: false },
        { ...reportsFields.ReportNumber, checked: true },
        { ...reportsFields.Status, checked: true },
        {
          ...reportsFields.TransferCompleteDate,
          checked: false,
          isHidden: this.isEndUser,
        },
        {
          ...reportsFields.TransferError,
          checked: false,
          isHidden: this.isEndUser,
        },
        {
          ...reportsFields.TransferPdfUrl,
          checked: false,
          isHidden: this.isEndUser,
        },
        {
          ...reportsFields.TransferSignedDate,
          checked: false,
          isHidden: this.isEndUser,
        },
        {
          ...reportsFields.TransferStatus,
          checked: false,
          isHidden: this.isEndUser,
        },
        {
          ...reportsFields.TransferredDate,
          checked: false,
          isHidden: this.isEndUser,
        },
        { ...reportsFields.VehicleType, checked: true, Sortable: true },
        { ...reportsFields.VerificationDate, checked: false },
      ];

      this.tabs = [
        {
          id: "AllReports",
          title: "כל הדוחות",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllReports,
          perPage: 20,
          fields,
          filters: [
            { ...reportsfilters.IsPaid, selected: this.$route.query.IsPaid },
            { ...reportsfilters.ReportStatus },
            // { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.CauseIds },
            { ...reportsfilters.Alerts },
            { ...reportsfilters.VehicleType },
            {
              ...reportsfilters.Profile,
              isHidden: !this.isAdmin && !this.isLeasingCompany,
              selected: this.$route.query.ProfileId,
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
              isHidden: !this.isLeasingCompany,
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            { ...reportsfilters.PlateNumber },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                    { ...FilterService.DateSearchTypes.DateTaken },
                    { ...FilterService.DateSearchTypes.LastPayDate },
                    { ...FilterService.DateSearchTypes.PaymentDate },
                    { ...FilterService.DateSearchTypes.ApprovalDate },
                    { ...FilterService.DateSearchTypes.VerificationDate },
                    { ...FilterService.DateSearchTypes.CreateDate },
                    {
                      ...FilterService.DateSearchTypes
                        .FirstDocumentTransferCompleteDate,
                    },
                    {
                      ...FilterService.DateSearchTypes
                        .FirstDocumentTransferDriverCompleteDate,
                    },
                  ],
            },
          ],
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: (() => {
            let actions = [TableActions.markAsPaid, TableActions.driverAssign, TableActions.addTask];
            if (this.isAdmin && !this.isSosnaPortal) {
              actions = actions.concat([
                {
                  ...TableActions.changeTransferStatusCompany,
                  Label: "שינוי סטטוס הסבת חברה",
                },
                {
                  ...TableActions.reportsDetailsScanning,
                  Label: "סריקת נתוני דוחות",
                },
                {
                  ...TableActions.reportsAlertsGenerator,
                  Label: "סריקת התראות",
                },
                {
                  ...TableActions.changeTransferStatusDriver,
                  Label: "שינוי סטטוס הסבת נהג",
                },
                TableActions.changeReportStatus,
              ]);
              if (this.isUserProfile && !this.isSosnaPortal) {
                actions = actions.concat([TableActions.removeFromProfile]);
              }
            }
            return actions;
          })(),
        },
        {
          id: "OpenReports",
          isPrimary: true,
          title: "דוחות פתוחים",
          newEntityComponent: NewReportEntity,
          getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
            const query = rest;
            if (!this.isLeasingCompany) query.TransferStatus = [];
            query.IsPaid = false;
            const newQuery = this.getFromStatusApproved(query);
            setPermanentQuery({
              // IsPaid: false,
              ...newQuery,
            });

            return this.getAllReports({ cancelToken, ...newQuery });
          },
          perPage: 20,
          fields,
          filters: [
            { ...reportsfilters.ReportStatus },
            // { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.ReportStatusApproved },

            { ...reportsfilters.CauseIds },
            { ...reportsfilters.Alerts },
            { ...reportsfilters.VehicleType },
            { ...reportsfilters.Profile, isHidden: !this.isLeasingCompany },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
              selected: [
                0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19,
              ],
              isHidden: !this.isLeasingCompany,
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              selected: [0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            { ...reportsfilters.PlateNumber },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                    { ...FilterService.DateSearchTypes.DateTaken },
                    { ...FilterService.DateSearchTypes.LastPayDate },
                    { ...FilterService.DateSearchTypes.PaymentDate },
                    { ...FilterService.DateSearchTypes.ApprovalDate },
                    { ...FilterService.DateSearchTypes.VerificationDate },
                    { ...FilterService.DateSearchTypes.CreateDate },
                    {
                      ...FilterService.DateSearchTypes
                        .FirstDocumentTransferCompleteDate,
                    },
                    {
                      ...FilterService.DateSearchTypes
                        .FirstDocumentTransferDriverCompleteDate,
                    },
                  ],
            },
          ],
          sortField: "DateTaken",
          sortOrder: "desc",
          excelExport: [
            {
              title: "יצוא טבלה",
              action: ({ loading, query }) => {
                if (!this.isLeasingCompany) query.TransferStatus = [];
                const newQuery = this.getFromStatusApproved(query);
                newQuery.isPaid = false;
                newQuery.ResultType = null;
                this.export({ loading, query: { ...newQuery } });
              },
            },
          ],
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          checkableRows: true,
          sideViewEntity,
          tableActions: (() => {
            let actions = [
              TableActions.approvePay,
              TableActions.disApprovePay,
              TableActions.markAsPaid,
              TableActions.driverAssign,
              TableActions.addTask,
            ];
            if (this.isAdmin && !this.isSosnaPortal) {
              actions = actions.concat([
                {
                  ...TableActions.reportsDetailsScanning,
                  Label: "סריקת נתוני דוחות",
                },
                {
                  ...TableActions.reportsAlertsGenerator,
                  Label: "סריקת התראות",
                },
                {
                  ...TableActions.produceSingleReference,
                  Label: 'הפק אסמכתה אחודה'
                },
                {
                  ...TableActions.changeTransferStatusCompany,
                  Label: "שינוי סטטוס הסבת חברה",
                },
                {
                  ...TableActions.changeTransferStatusDriver,
                  Label: "שינוי סטטוס הסבת נהג",
                },
                TableActions.changeReportStatus,
              ]);
              if (this.isUserProfile && !this.isSosnaPortal) {
                actions = actions.concat([TableActions.removeFromProfile]);
              }
            }
            return actions;
          })(),
        },
        {
          id: "OpenTransferedReports",
          isPrimary: true,
          title: "פתוחים שהוסבו לנהג",
          newEntityComponent: NewReportEntity,
          getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
            const query = rest;
            if (!this.isLeasingCompany) query.TransferStatus = [];
            query.IsPaid = false;
            const newQuery = this.getFromStatusApproved(query);
            setPermanentQuery({
              // IsPaid: false,
              ...newQuery,
            });

            return this.getAllReports({ cancelToken, ...newQuery });
          },
          perPage: 20,
          fields,
          filters: [
            { ...reportsfilters.ReportStatus },
            // { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.ReportStatusApproved },

            { ...reportsfilters.CauseIds },
            { ...reportsfilters.Alerts },
            { ...reportsfilters.VehicleType },
            { ...reportsfilters.Profile, isHidden: !this.isLeasingCompany },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
              selected: [
                0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19,
              ],
              isHidden: !this.isLeasingCompany,
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              selected: [2]
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            { ...reportsfilters.PlateNumber },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                    { ...FilterService.DateSearchTypes.DateTaken },
                    { ...FilterService.DateSearchTypes.LastPayDate },
                    { ...FilterService.DateSearchTypes.PaymentDate },
                    { ...FilterService.DateSearchTypes.ApprovalDate },
                    { ...FilterService.DateSearchTypes.VerificationDate },
                    { ...FilterService.DateSearchTypes.CreateDate },
                    {
                      ...FilterService.DateSearchTypes
                        .FirstDocumentTransferCompleteDate,
                    },
                    {
                      ...FilterService.DateSearchTypes
                        .FirstDocumentTransferDriverCompleteDate,
                    },
                  ],
            },
          ],
          sortField: "DateTaken",
          sortOrder: "desc",
          excelExport: [
            {
              title: "יצוא טבלה",
              action: ({ loading, query }) => {
                if (!this.isLeasingCompany) query.TransferStatus = [];
                const newQuery = this.getFromStatusApproved(query);
                newQuery.isPaid = false;
                newQuery.ResultType = null;
                this.export({ loading, query: { ...newQuery } });
              },
            },
          ],
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          checkableRows: true,
          sideViewEntity,
          tableActions: (() => {
            let actions = [
              TableActions.approvePay,
              TableActions.disApprovePay,
              TableActions.markAsPaid,
              TableActions.driverAssign,
              TableActions.addTask,
            ];
            if (this.isAdmin && !this.isSosnaPortal) {
              actions = actions.concat([
                {
                  ...TableActions.reportsDetailsScanning,
                  Label: "סריקת נתוני דוחות",
                },
                {
                  ...TableActions.reportsAlertsGenerator,
                  Label: "סריקת התראות",
                },
                {
                  ...TableActions.changeTransferStatusCompany,
                  Label: "שינוי סטטוס הסבת חברה",
                },
                {
                  ...TableActions.changeTransferStatusDriver,
                  Label: "שינוי סטטוס הסבת נהג",
                },
                TableActions.changeReportStatus,
              ]);
              if (this.isUserProfile && !this.isSosnaPortal) {
                actions = actions.concat([TableActions.removeFromProfile]);
              }
            }
            return actions;
          })(),
        },
        {
          id: "ApprovedReports",
          isPrimary: true,
          title: "מאושרים לתשלום",
          newEntityComponent: NewReportEntity,
          getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
            const query = rest;
            query.IsPaid = false;
            if (!this.isLeasingCompany) query.TransferStatus = [];
            console.log("query:", query);
            const newQuery = this.getFromStatusApproved(query);
            console.log("newQuery:", newQuery);
            setPermanentQuery({
              // IsPaid: false,
              ...newQuery,
            });

            return this.getAllReports({ cancelToken, ...newQuery });
          },
          perPage: 20,
          fields,
          filters: [
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.ReportStatusApproved, selected: true },
            { ...reportsfilters.CauseIds },
            { ...reportsfilters.Alerts },
            { ...reportsfilters.VehicleType },
            { ...reportsfilters.Profile, isHidden: !this.isLeasingCompany },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
              selected: [
                0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19,
              ],
              isHidden: !this.isLeasingCompany,
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PlateNumber },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                    { ...FilterService.DateSearchTypes.DateTaken },
                    { ...FilterService.DateSearchTypes.LastPayDate },
                    { ...FilterService.DateSearchTypes.PaymentDate },
                    { ...FilterService.DateSearchTypes.ApprovalDate },
                    { ...FilterService.DateSearchTypes.VerificationDate },
                    { ...FilterService.DateSearchTypes.CreateDate },
                    {
                      ...FilterService.DateSearchTypes
                        .FirstDocumentTransferCompleteDate,
                    },
                    {
                      ...FilterService.DateSearchTypes
                        .FirstDocumentTransferDriverCompleteDate,
                    },
                  ],
            },
          ],
          sortField: "DateTaken",
          sortOrder: "desc",
          excelExport: [
            {
              title: "יצוא טבלה",
              action: ({ loading, query }) => {
                const newQuery = this.getFromStatusApproved(query);
                newQuery.ResultType = null;
                this.export({ loading, query: { ...newQuery } });
              },
            },
          ],
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          checkableRows: true,
          sideViewEntity,
          tableActions: (() => {
            let actions = [
              TableActions.approvePay,
              TableActions.disApprovePay,
              TableActions.markAsPaid,
              TableActions.driverAssign,
              TableActions.addTask
            ];
            if (this.isAdmin && !this.isSosnaPortal) {
              actions = actions.concat([
                {
                  ...TableActions.changeTransferStatusCompany,
                  Label: "שינוי סטטוס הסבת חברה",
                },
                {
                  ...TableActions.produceSingleReference,
                  Label: 'הפק אסמכתה אחודה'
                },
                {
                  ...TableActions.changeTransferStatusDriver,
                  Label: "שינוי סטטוס הסבת נהג",
                },
                TableActions.changeReportStatus,
              ]);
              if (this.isUserProfile && !this.isSosnaPortal) {
                actions = actions.concat([TableActions.removeFromProfile]);
              }
            }
            return actions;
          })(),
        },
        {
          id: "PaidReports",
          title: "דוחות ששולמו",
          newEntityComponent: NewReportEntity,
          getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
            const query = rest;

            query.IsPaid = true;
            setPermanentQuery({
              IsPaid: true,
            });

            return this.getAllReports({ cancelToken, ...query });
          },
          excelExport: [
            {
              title: "יצוא טבלה",
              action: ({ loading, query }) => {
                const obj = { ...query };
                obj.IsPaid = true;
                this.export({ loading, query: obj }, true);
              },
            },
          ],
          perPage: 20,
          fields,
          filters: [
            // { ...reportsfilters.IsPaid },
            // { ...reportsfilters.ReportStatus },
            // { ...reportsfilters.NotReportStatus },
            // { ...reportsfilters.Alerts },
            { ...reportsfilters.Profile, isHidden: !this.isLeasingCompany },
            { ...reportsfilters.Driver },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.Municipality },
            { ...reportsfilters.PlateNumber },
            { ...reportsfilters.VehicleType },
            // {
            //   ...reportsfilters.DriverTransferStatusWNotTransferred,
            // },
            // {
            //   ...reportsfilters.TransferStatusWNotTransferred,
            //   isHidden: !this.isLeasingCompany,
            // },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                    { ...FilterService.DateSearchTypes.DateTaken },
                    { ...FilterService.DateSearchTypes.LastPayDate },
                    { ...FilterService.DateSearchTypes.PaymentDate },
                    { ...FilterService.DateSearchTypes.ApprovalDate },
                    { ...FilterService.DateSearchTypes.VerificationDate },
                    { ...FilterService.DateSearchTypes.CreateDate },
                    {
                      ...FilterService.DateSearchTypes
                        .FirstDocumentTransferCompleteDate,
                    },
                    {
                      ...FilterService.DateSearchTypes
                        .FirstDocumentTransferDriverCompleteDate,
                    },
                  ],
            },
          ],
          sortField: "DateTaken",
          sortOrder: "desc",
          fastSearch: true,
          fastSearchOptions: {
            propName: "SearchTerm",
            label: "חפש מספר דוח/אסמכתה",
          },
          sideViewEntity,
        },
        {
          id: "SalaryReport",
          newEntityComponent: NewReportEntity,
          title: 'דו"ח שכר',
          isHidden: this.$store.state.profile.sosna,
          getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
            const query = rest;

            query.IsPaidByDriver = true;
            setPermanentQuery({
              IsPaidByDriver: true,
            });

            return this.getAllReports({ cancelToken, ...query });
          },
          excelExport: [
            {
              title: "יצוא טבלה",
              action: ({ loading, query }) => {
                const obj = { ...query };
                obj.IsPaidByDriver = true;
                obj.ResultType = '';
                this.export({ loading, query: obj });
              },
            },
          ],
          perPage: 20,
          fields,
          filters: [
            { ...reportsfilters.IsPaid },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.CauseIds },
            { ...reportsfilters.Alerts },
            { ...reportsfilters.Profile, isHidden: !this.isLeasingCompany },
            { ...reportsfilters.Driver },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.Municipality },
            { ...reportsfilters.PlateNumber },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: [
                { ...FilterService.DateSearchTypes.DateTaken },
                { ...FilterService.DateSearchTypes.PaymentDate },
                {
                  ...FilterService.DateSearchTypes
                    .FirstDocumentTransferCompleteDate,
                },
                {
                  ...FilterService.DateSearchTypes
                    .FirstDocumentTransferDriverCompleteDate,
                },
              ],
            },
          ],
          sortField: "DateTaken",
          sortOrder: "desc",
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
        },
        {
          id: "NotMyReports",
          newEntityComponent: NewReportEntity,
          title: "דוחות שלא שלי",
          isHidden: this.$store.state.profile.sosna,
          getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
            const query = rest;
            setPermanentQuery({
              ...query,
            });

            return this.getAllReports({ cancelToken, ...query });
          },
          excelExport: [
            {
              title: "יצוא טבלה",
              action: ({ loading, query }) => {
                const obj = { ...query };
                obj.ResultType = null;
                this.export({ loading, query: obj });
              },
            },
          ],
          perPage: 20,
          fields,
          filters: [
            { ...reportsfilters.IsPaid },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.CauseIds },
            { ...reportsfilters.Alerts, selected: 1 },
            { ...reportsfilters.Profile, isHidden: !this.isLeasingCompany },
            { ...reportsfilters.Driver },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.Municipality },
            { ...reportsfilters.PlateNumber },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: [
                { ...FilterService.DateSearchTypes.DateTaken },
                { ...FilterService.DateSearchTypes.PaymentDate },
                {
                  ...FilterService.DateSearchTypes
                    .FirstDocumentTransferCompleteDate,
                },
                {
                  ...FilterService.DateSearchTypes
                    .FirstDocumentTransferDriverCompleteDate,
                },
              ],
            },
          ],
          sortField: "DateTaken",
          sortOrder: "desc",
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
        },
      ];
      this.tabs = this.tabs.filter((t) => !t.isHidden);
    },
    async onPlateNumberClick(context) {
      if (context.item.IsVehicleMine) {
        context.default();
      } else {
        const contract = await ReportService.getReportContract(context.item.Id);
        this.$refs.entity
          .setExtraSideMenu({
            component: VehicleContractEntity,
            title: "חוזה לרכב",
            recordUrl: "contracts",
            preview: true,
            readOnly: true,
            Id: contract.data.VehicleContractId,
          })
          .show();
      }
    },
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    export({ loading, query }, isPaidReportsTab) {
      loading(true);
      let currExport = "exportXsl";
      if (isPaidReportsTab) currExport = "exportPaidReportsXsl";
      ReportService[currExport](query)
        // ReportService.exportXsl(query)
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    exportByMunic({ loading, query }) {
      loading(true);
      ReportService.exportReportsXslBy(query, "Municipalities")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי עיריות");
        })
        .catch(() => {})
        .finally(() => {
          loading(false);
        });
    },
    exportByClient({ loading, query }) {
      loading(true);
      ReportService.exportReportsXslBy(query, "Profiles")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי לקוחות");
        })
        .catch(() => {})
        .finally(() => {
          loading(false);
        });
    },
    getAllReports({ cancelToken, ...rest }) {
      return ReportService.search(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getPayments({ cancelToken }) {
      return ReportService.getPayments(cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    getFromStatusApproved(query) {
      // eslint-disable-next-line eqeqeq
      if (typeof query.StatusApproved == "boolean") {
        if (query.StatusApproved) {
          if (query.Status) {
            if (!query.Status.includes("ApproveForPayment")) {
              query.Status.push("ApproveForPayment");
            }
          } else query.Status = ["ApproveForPayment"];
        } else {
          if (query.Status) {
            query.Status = query.Status.filter(
              (item) => item !== "ApproveForPayment"
            );
          }
          query.NotStatus = ["ApproveForPayment"];
        }
        delete query.StatusApproved;
      }
      if (query.StatusApproved === null) {
        delete query.NotStatus;
        if (!query.Status) delete query.Status;
        else {
          query.Status = query.Status.filter(
            (item) => item !== "ApproveForPayment"
          );
        }
        delete query.StatusApproved;
      }
      return query;
    },
  },
  watch: {
    isReady(value) {
      if (value) {
        this.create();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #ff7a59;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    color: white;
    cursor: pointer;
}
</style>
